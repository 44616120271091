.banner {
    height: 68px;
    width: 100%;
    background: linear-gradient(150.1deg, #FF00FF -236.59%, #05FAFF 307.56%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 90px;
}

.banner-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #fff;
}

.banner-btn {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-weight: 600;
    color: #0F1422;
    padding: 8px 16px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .banner {
        padding: 0 10px;
        height: 50px;
    }

    .banner-text {
        font-size: 14px;
    }
    
    .banner-btn {
        font-size: 12px;
        padding: 4px 12px;
    }
}
@primary-color: #1DA57A;@link-color: #ffffff;