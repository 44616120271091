.bg-blue {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, #1D26FF 247.55%);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 450px;
    min-height: 450px;
    border-radius: 80px;
}

.container-bg-logos {
    position: relative;
}

.media-section-container {
    position: relative;
    border: 1px solid transparent;
    background: linear-gradient(71deg, rgb(20, 16, 31), rgb(29, 25, 57), rgb(19, 15, 51));
    background-clip: padding-box;
    margin-top: 240px;
    border-radius: 50px;
    width: 100%;
    max-width: 1300px;
    margin-left: 40px;
    margin-right: 40px;

    .fifth-content-container {
        padding: 50px 70px 50px;
    }
  
    .media-mobile-container {
      display: none;
    }
  
    &::after {
      position: absolute;
      top: -1px; 
      bottom: -1px;
      left: -1px; 
      right: -1px;
      background: linear-gradient(71deg, rgb(7,7,23), rgb(95,111,179), rgb(10,11,57));
      content: '';
      z-index: -1;
      border-radius: 50px;
    }
  
    .investor-container {
      transition: all ease 0.3s;
    }
  
    .investor-container:hover {
      border-color: #fff;
    }
  
    .media-container-link:hover,
    .media-container-link:link,
    .media-container-link:visited {
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
    }
  
    .logo-without-text {
      width: auto !important;
      height: auto !important;
    }
  
    .indents-logo {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    .media-section-container {
        margin-top: 96px;
    }
  }

@media screen and (max-width: 992px) {
    .media-section-container {
      border-radius: 20px;
  
      &::after {
        border-radius: 20px;
      }
    }
}

@media screen and (max-width: 800px) {
    .media-section-container {
        margin-top: 160px;
        margin-left: 32px;
        margin-right: 32px;
            .fifth-content-container {
                padding: 48px 10px 56px !important;
            }
            .investor-container {
                padding: 0 20px;
                max-height: 56px;
            }

            .fifth-investors {
            display: none;
            }

            .media-mobile-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 32px;

            .text-logo {
                font-size: 10px;
            }

            .media-between {
                margin-top: 10px;
            }
            .logo {
                width: 26px;
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .media-section-container {
        margin-top: 64px;
    }
    .bg-blue {
        height: 550px;
        border-radius: 24px;
    }
}

@media screen and (max-width: 425px) {
    .bg-blue {
        height: 480px;
    }
    .media-section-container {
        margin-left: 12px;
        margin-right: 12px;
        .fifth-content-container {
            padding: 20px !important;
            padding-bottom: 32px !important;
            .fifth-container-title {
                margin: 0 auto;
            }
        }
        .media-mobile-container {
            justify-content: flex-start;
            margin-top: 15px;
        }
        .investor-container {
            padding: 0 16px;
        }
        .logo-without-text {
            max-width: 125px;
        }
        .benzinga-logo {
            max-width: 98px !important;    
        }
        .coinspeak-logo {
            max-width: 93px !important;    
        }
    }
}
@primary-color: #1DA57A;@link-color: #ffffff;