@import "../../antd.customize.less";

.images-container {
  position: relative;
  width: 96%;
  height: 100%;
  min-height: 938px;
  margin: 0 auto;
  margin-bottom: 100px;

  .content-screen-app {
    max-width: 1240px;
    width: 96%;
    position: absolute;
    top: 50px;
    background-repeat: no-repeat;
    .image-set('/assets/App-UI/App-UI');
    background-size: contain;
    height: 100%;
  }

  .animation-content-screen-app {
    width: 98%;
    max-width: 1240px;
    transform: translateY(0);
  }

  .screen-app {
    transition: 3s all ease;
    width: 100%;
    //height: 100%;
    max-width: 1260px;
    .image-set('/assets/App-border/App--border');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .screen-app-animation {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }
}

@media screen and (max-width: 1200px) {
  .images-container {
    min-height: 854px;
  }
}

@media screen and (max-width: 1024px) {
  .images-container {
    min-height: 750px;

    .content-screen-app {
      top: 36px;
    }
  }
}

@media screen and (max-width: 768px) {

  .images-container {
    width: 100%;
  }

  .content-screen-app{
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .images-container {
    min-height: 600px;
  }
}

@media screen and (max-width: 600px) {
  .images-container {
    min-height: 444px;
    padding-bottom: 0;

    .content-screen-app {
      top: 20px;
    }
  }
}

@media screen and (max-width: 425px) {
  .images-container {
    min-height: 300px;

    .content-screen-app {
      top: 15px;
      width: 98%;
      .image-set('/assets/App-UI/App-UI-mobile');
    }

    .screen-app {
      .image-set('/assets/App-border/App--border-mobile');
      top: 16px;
    }
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;