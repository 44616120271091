@normal-width: 95%;
@normal-width-998: 92%;
@normal-width-768: 90.5%;
@normal-width-375: 89.5%;
@max-width: 1260px;

@normal-color: white;
@normal-font-color: rgba(255, 255, 255, 0.5);
@normal-background: rgba(37, 41, 54, 0.4);
@background-box: linear-gradient(71deg, rgb(8, 5, 9), rgb(26, 23, 28), rgb(8, 5, 9));

@normal-font-description: 20px;
@small-font-size: 18px;
@topic-font: 42px;
@description-font: 28px;
@padding-width: 40px;

@normal-font-description-998: 18px;
@small-font-size-998: 12px;
@topic-font-998: 32px;
@description-font-998: 20px;
@padding-width-998: 40px;

@normal-font-description-768: 16px;
@small-font-size-768: 14px;
@topic-font-768: 24px;
@description-font-768: 16px;
@padding-width-768: 40px;

@translate-animation: translateY(-70vh);
@revert-translate-animation: translateY(0);
@content-translate-animation: translateY(calc(-70vh + 80px));

.image-set(@img) {
  /* autoprefixer: off */
  background-image: url("@{img}.png");
  background-image: -webkit-image-set(
    url("@{img}.webp") 1x,
    url("@{img}.png") 1x,
    url("@{img}@2x.webp") 2x,
    url("@{img}@2x.png") 2x,
  );
  background-image: image-set(
    url("@{img}.png") 1x,
    url("@{img}@2x.png") 2x,
  );
}

.reset_width-40() {
  padding: 0 40px;
  width: 100%;
}

.reset_width-36() {
  padding: 0 36px;
  width: 100% !important;
}

.reset_width-20() {
  padding: 0 20px;
  width: 100% !important;
}

.reset_width-8() {
  padding: 0 8px;
  width: 100% !important;
}
