@import "../../antd.customize.less";

.notice-style {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #EF0700;
  text-align: left;
  font-size: 16px;
  line-height: 32px;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.form-title {
  color: @normal-color;
  font-size: 20px;
  margin-bottom: 16px;
  line-height: 32px;
  font-style: normal;
  font-weight: 700;
  opacity: 0.5;

  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }
}

.input-class {
  position: relative;
  background: transparent;
  color: white;
  padding: 0 0 0 20px;
  width: 100%;
  border: 0;
  font-style: normal;
  max-width: 560px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 28px;
  }
}

.input-class::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: @small-font-size;
  color: #FFFFFF;
  opacity: 0.5;
  font-style: normal;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.input-class::-moz-placeholder { /* Firefox 19+ */
  font-size: @small-font-size;
  color: #FFFFFF;
  opacity: 0.5;
  font-style: normal;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.input-class:-ms-input-placeholder { /* IE 10+ */
  font-size: @small-font-size;
  color: #FFFFFF;
  opacity: 0.5;
  font-style: normal;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.input-class:-moz-placeholder { /* Firefox 18- */
  font-size: @small-font-size;
  color: #FFFFFF;
  opacity: 0.5;
  font-style: normal;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.input-class:focus {
  border: none;
  box-shadow: none;
}

.input-class:focus-visible {
  border: none;
  box-shadow: none;
}

.input-class:-webkit-autofill,
.input-class:-webkit-autofill:focus {
  // hack to prevent white background on autoselect
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-subscribe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255 255 255 / 10%);
  position: relative;
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;
  padding: 5px;
  height: auto;
}

.form-button {
  min-width: 143px !important;
  cursor: pointer;
  height: 52px !important;
  font-size: 18px !important;

  @media screen and (max-width: 768px) {
    min-width: 106px !important;
    height: 40px !important;
    font-size: 14px !important;
  }
}

.notice-success {
  text-overflow: ellipsis;
  position: relative;
  border-radius: 12px;
  height: 64px;
  width: 100%;
  max-width: 560px;
  margin-top: 46px;

  border: 1px solid transparent;
  background: black;
  background-clip: padding-box;

  color: #2FCB89;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.notice-success::after {
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
  z-index: -1;
  background: linear-gradient(134.52deg, rgba(47, 203, 137, 0.7) 0.07%, rgba(47, 203, 137, 0) 48.97%, rgba(47, 203, 137, 0.7) 99.95%);;
  border-radius: 12px;
  content: '';
}

.form-button-disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  pointer-events: none;
}

.form-button-disabled:hover {
  background-color: unset !important;
  color: unset !important;
}

@media screen and (max-width: 1084px) {
  div.first-content-container {
    width: 83%;
  }

  .second-container .second-card .mobile-class div.img-wrap {
    height: 90%;
    margin-right: -60px;
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;