.transparent-link {
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  backdrop-filter: blur(200px);
  border-radius: 8px;
  width: 124px;
  height: 38px;
  position: relative;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-clip: padding-box;

  &:hover {
    background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
    -webkit-text-fill-color: unset;
  }
}

.transparent-link::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(71deg, #142033, #a93be4, #142033);
  content: '';
  z-index: -1;
  border-radius: 8px;
}

@primary-color: #1DA57A;@link-color: #ffffff;