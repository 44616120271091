@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import "typeface-sofia";
html, body {
  overflow-y: hidden;
}
body {
  margin: 0 !important;
  font-family: "Sora" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-row {
  min-width: 100%;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}

/* Optional: show position indicator in red */
/*::-webkit-scrollbar-thumb {*/
/*  background: #FF0000;*/
/*}*/
