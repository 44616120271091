@import "../../antd.customize.less";

.empty-container {
    padding-top: 86px;
    padding-bottom: 10px;
    height: 100vh;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding-left: 110px;
    position: relative;
    max-width: 1440px;
}

.empty-purple-bg {
    background-image: url('../../assets/Ellipse 48.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
}

.empty-bg {
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 762px;
    z-index:-1;
    pointer-events: none;

    &.bg-404 {
        position: relative;
        max-height: 769px;
        background-position: bottom right;
        // .image-set('/assets/404');
        .image-set('/assets/404-black');
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            z-index: 1;
            pointer-events: none;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            height: 200px;
        }
    }

    &.bg-blank {
        // .image-set('/assets/blank-page');
        .image-set('/assets/blank-black');
        background-position: center right;
    }
}

.empty-title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 800;
    margin-bottom: 12px;
}

.empty-description {
    font-size: 20px;
    line-height: 36px;
    font-weight: 600;
    opacity: 0.5;
    max-width: 446px;
}

.empty-description-down {
    margin-bottom: 40px;
}

@media screen and (min-width: 1441px) {
    .empty {
        display: flex;
        justify-content: center;
    }

  .empty-container {
      max-width: 1360px;
    justify-content: space-between;
    .empty-purple-bg {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
    .empty-container {
        padding-left: 56px;
        height: 75vh;

        &.blank {
            padding-bottom: 60px;
        }
    }
    .empty-bg {
        width: 539px;
        &.bg-blank {
            margin-bottom: -170px;
        }
    }
  }

  @media screen and (max-width: 800px) {
    .empty-container {
        height: 80vh;
        padding-left: 32px;
        align-items: flex-start;

        &.blank {
            height: 75vh;
            padding-bottom: 0;
        }
    }

    .empty-text {
        margin-top: 109px;
    }

    .empty-bg {
        width: 401px;

        &.bg-blank {
            margin-bottom: 0;
            margin-top: 100px;
            width: 500px;
        }
    }

    .empty-purple-bg {
        height: 600px;
        background-size: contain;
    }
  }

@media screen and (max-width: 600px) {
    .empty-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        height: 1000px;
        padding-left: 0;

        &.blank {
            height: 783px;

            .empty-text {
                margin-top: 35px;
            }
    
            .empty-purple-bg {
                top: -80px;
            }
        }
    }

    .empty-shadow {
      bottom: 48px;
    }

    .empty-purple-bg {
       background-image: url('../../assets/Ellipse-mobile.svg');
        background-size: 100% 100%;
        right: 0;
        left: auto;
    }

    .empty-bg {
        background-position: center bottom;
        margin-right: 25px;

        &.bg-blank {
            margin-top: 0;
            background-size: contain;
            width: 345px;
        }
    }

    .empty-description {
        font-size: 16px;
        line-height: 30px;
        max-width: 311px;
    }
  }

  @media screen and (max-width: 375px) {
    .empty-purple-bg {
        background-size: contain;
        top: 0;
        height: 100%;
     }
     .empty-container.blank {

        .empty-purple-bg {
            top: -140px;
        }
    }
  }
@primary-color: #1DA57A;@link-color: #ffffff;