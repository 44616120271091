.buy-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('assets/background-light.svg') no-repeat;
  z-index: 1;
  pointer-events: none;
}

.top-app-bar {
  background: none;
}

.navbar {
  padding: 0 0;
  max-width: 1260px;
}

.buy-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 90px;
  position: relative;

  @media screen and (max-width: 991px) {
    padding: 0 56px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 375px) {
    padding: 0 12px;
  }
}

.buy-content {
  max-width: 1260px;
  width: 100%;
  margin-top: 164px;
  color: white;
  text-align: left;

  @media screen and (max-width: 991px) {
    margin-top: 128px;
  }

  @media screen and (max-width: 375px) {
    margin-top: 96px;
  }

  & .buy-title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  @media screen and (max-width: 375px) {
    & .buy-title {
      font-size: 30px;
      line-height: 46px;
    }
  }
}

.buy-blocks {
  display: flex;
  margin-top: 56px;
  margin-bottom: 87px;

  @media screen and (max-width: 991px) {
    margin-bottom: 34px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 28px;
  }
  @media screen and (max-width: 375px) {
    margin-top: 32px;
    margin-bottom: 22px;
  }
}

.buy-item {
  width: 100%;
  border-radius: 20px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: black;
  background-clip: padding-box;
}

.buy-item::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(71deg, #121013, #b0afb0, #121013);
  content: '';
  z-index: -1;
  border-radius: 20px;
}

.buy-card-content {
  padding: 24px;
}

.first-block {
  margin-right: 16px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.card-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  display: flex;
  align-items: center;
}

.card-header img {
  margin-right: 16px;
}

.card-description {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-top: 24px;
  margin-bottom: 16px;
}

.usdh-icon {
  border-radius: 50%;
}

.buy-card-divider {
  background: radial-gradient(rgba(255, 255, 255, 1), rgba(54, 52, 55, 0.2));
  width: 100%;
  height: 1px;
}

.buy-platforms a {
  display: inline-block;
  margin-right: 30px;
  margin-top: 18px;
}

.floated-element {
  position: relative;
  z-index: 2;
}

@primary-color: #1DA57A;@link-color: #ffffff;