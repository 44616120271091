@tooltip-background-color: #000;

.hubble-tooltip {
  .ant-tooltip-inner {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    padding: 8px;
    background: @tooltip-background-color;
    box-shadow: none;
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;