.container-animation-video {
    z-index: -2;
    margin-top: -28vh;
    width: 100%;
    height: 1080px;
    position: relative;
    overflow-x: hidden;
    video {
      width: 100%;
      max-width: 1920px;
    }
}

.animation-video {
    z-index: -2;
    max-height: 100%;
}

.hide-video {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0 !important;
}

@media screen and (max-height: 920px) {
    .container-animation-video {
        margin-top: -40vh;
    }
}

@media screen and (max-width: 768px) {
    .container-animation-video {
        margin-top: -40vh;
    }
    .animation-video {
        max-width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .container-animation-video {
        max-height: 920px;
        margin-top: -10vh;
    }
}

@media screen and (max-height: 1024px) and (max-width: 800px) {
    .container-animation-video {
        max-height: 800px;
        margin-top: -16vh;
    }
}

@media screen and (max-height: 880px) {
    .container-animation-video {
        max-height: 800px;
        margin-top: -15vh;
    }
}

@media screen and (max-height: 850px) and (max-width: 600px) {
    .container-animation-video {
        max-height: 550px;
        margin-top: -5vh;
    }
}

@media screen and (max-height: 700px) {
    .container-animation-video {
        max-height: 500px;
        margin-top: -16vh;
    }
}

@media screen and (max-height: 900px) and (max-width: 600px) {
    .container-animation-video {
        max-height: 500px;
        margin-top: -10vh;
        .video {
            min-width: 1920px !important;
        }
    }
}

@primary-color: #1DA57A;@link-color: #ffffff;