@import '~antd/dist/antd.less';
@import "src/antd.customize.less";

* {
  font-feature-settings: normal!important;
  font-variant: normal!important;
}


body, html {
  max-width: 100%;
  overflow-x: clip;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  background-color: #000;
}

html {
  height: -webkit-fill-available;
}

.disable-overflow {
  overflow-y: hidden !important;
}

.App {
  text-align: center;
  overflow-x: clip;
  max-height: 100vh;
  overflow-y: auto;
}

a span {
  color: white !important;
  transition: color 0.3s;
}

a span:hover {
  background: -webkit-linear-gradient(265.37deg, #05FAFF -55.16%, #7A84FF 129.88%);
  transition: all 0.3s;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-layout {
  background: none !important;
}

Header {
  background: none !important;
}

html {
  height: auto;
}

.ant-layout-header {
  padding: 0px;
}

.ant-modal {
  color: rgba(255, 255, 255, 1);
}

.ant-modal-footer {
  display: none;
}

.ant-modal-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(37, 41, 54);
  border-bottom-color: rgba(0, 0, 0, 0.4);
}

.ant-modal-title {
  color: white;
}

.ant-modal-close-icon {
  color: white;
}

.ant-modal-content {
  border-radius: 20px;
  background-color: rgba(37, 41, 54);
}

.ant-dropdown {
  min-width: 110px !important;
  z-index: 9999;
}

.ant-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.ant-dropdown-link:hover {
  background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-dropdown-trigger:hover,
.ant-dropdown-trigger:link,
.ant-dropdown-trigger:visited {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-dropdown-menu {
  position: relative;
  border: 2px solid transparent;
  background: @background-box;
  background-clip: padding-box;
  border-radius: 12px;

  &::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(71deg, rgb(18,16,19), rgb(176,175,176), rgb(18,16,19));
    content: '';
    z-index: -1;
    border-radius: 12px;
  }

  .ant-dropdown-menu-item {
    a:hover,
    a:link,
    a:visited {
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.ant-dropdown-menu-item {
  text-align: left;
  margin: 0 auto;
  border-radius: 6px;
  display: block;
  max-width: 91%;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

.ant-dropdown-menu-item:hover {
  background-color:#FFFFFF1A;
}

.hide-block {
  opacity: 0;
}

.scrolled {
  background-color: black !important;
}

@media screen and (max-width: 768px) {
  .ant-dropdown-link:hover {
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}




@primary-color: #1DA57A;@link-color: #ffffff;