@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
@import "../../antd.customize.less";

.slider {
  .Card-class {
    position: relative;
    width: 100%;
      border: 1px solid transparent;
      background: @background-box;
      background-clip: padding-box;
      border-radius: 40px;

      &::after {
        position: absolute;
        top: -1px; 
        bottom: -1px;
        left: -1px; 
        right: -1px;
        background: linear-gradient(71deg, rgb(18,16,19), rgb(176,175,176), rgb(18,16,19));
        content: '';
        z-index: -1;
        border-radius: 40px;
      }
  }

  .slick-slide {
    margin-right: 12px;
    width: 311px !important;
  }

  .eighth-single-card {
    padding: 20px;
    padding-bottom: 25px !important;
    text-align: left;

    img {
      width: 275px;
      max-height: 180px;
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  .eighth-title {
    letter-spacing: -0.02em;
    font-style: normal;
    font-weight: 600;
    color: white;
    font-size: 20px !important;
    line-height: 28px;
    margin-top: 20px;
    padding-left: 2px;
    padding-top: 5px;
  }

  .eighth-description {
    font-size: 14px !important;
    color: @normal-font-color;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 7px;
    padding-left: 2px;
  }

  .eighth-button {
    margin-top: 25px;
    margin-bottom: 6px;
    padding-left: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  img {
    border-radius: 25px;
    max-height: 237px;
  }
}

.slick-arrow {
  display: none !important;
}

@primary-color: #1DA57A;@link-color: #ffffff;