@import "src/antd.customize.less";

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.1s;
}

a {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:active {
    background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.header {
  padding: 12px 0;
  position: relative;

  @media (min-width: 768px) {
    padding: 20px 0;
  }

  &.banner-show {
    .header-nav {
      @media (max-width: 991px) {
        height: calc(100vh - 68px);
      }

      @media (max-width: 600px) {
        height: calc(100vh - 50px);
      }
    }
  }

  .button {
    font-size: 16px;
    white-space: nowrap;
    height: 38px;
    width: 130px;
  }

  &-logo {
    position: relative;
    z-index: 3334;

    .desktop {
      display: none;

      @media (min-width: 992px) {
        display: block;
      }
    }

    .mobile {
      display: block;

      @media (min-width: 992px) {
        display: none;
      }
    }

    @media (min-width: 992px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-burger {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 24px;
    background: @background-box;
    background-clip: padding-box;

    @media (min-width: 992px) {
      display: none;
    }

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(71deg, rgb(18, 16, 19), rgb(176, 175, 176), rgb(18, 16, 19));
      content: '';
      z-index: -1;
      border-radius: 8px;
    }

    svg {
      height: 32px;
      position: absolute;
      width: 32px;
      left: 0;
      top: 0;
    }

    .x {
      transform: scale(0);
      transition: transform 300ms;
    }

    .line {
      fill: none;
      stroke: @normal-color;
      stroke-width: 6px;
      stroke-linecap: round;
      stroke-linejoin: round;
      transform-origin: 50%;
      transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms, transform 400ms 100ms;
    }

    .x .line {
      stroke-width: 5.5px;
    }

    .x {
      transition: transform 300ms;
    }

    .line {
      transform-origin: 50%;
      transition: transform 300ms 100ms;
    }

    &.active .line {
      transition: transform 300ms;
    }

    &.active .line1 {
      transform: translateX(18px) translateY(-3px) rotate(-45deg) scale(.7);
    }

    &.active .line2 {
      transform: translateX(-18px) translateY(-3px) rotate(45deg) scale(.7);
    }

    &.active .line3 {
      transform: translateY(0px) rotate(45deg) scale(.7);
    }

    &.active .line4 {
      transform: translateY(0px) rotate(-45deg) scale(.7);
    }

    &.active .line5 {
      transform: translateX(18px) translateY(3px) rotate(45deg) scale(.7);
    }

    &.active .line6 {
      transform: translateX(-18px) translateY(3px) rotate(-45deg) scale(.7);
    }

    &.active .x {
      transition: transform 300ms 100ms;
      transform: scale(1);
    }
  }

  &-mobile-style {
    @media (min-width: 992px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: 991px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-scroll {
    @media (max-width: 991px) {
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 84px;
    }

    @media (max-width: 767px) {
      padding-bottom: 59px;
    }
  }

  &-nav {
    width: 100%;
    padding: 62px 32px 0;

    .button {
      display: flex;
      min-height: 46px;

      @media (min-width: 992px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      padding: 78px 56px 0;
    }

    @media (min-width: 992px) {
      padding: 0 14px 0 0;
    }

    @media (max-width: 991px) {
      position: absolute;
      height: 100vh;
      width: 100%;
      background: #000000;
      z-index: 3333;
      top: 0;
      left: 0;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &-center {
    max-width: @max-width;
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  &-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    padding-left: 0;
  }

  .social {
    &-list {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      list-style: none;

      @media (min-width: 768px) {
        margin-bottom: 72px;
      }

      @media (min-width: 992px) {
        margin-bottom: 0;
      }

      .header-border {
        @media (min-width: 992px) {
          opacity: 0;
        }
      }

      li {
        margin: 0 12px;
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        svg {
          @media (max-width: 991px) {
            width: 32px;
            height: 24px;
          }

          @media (max-width: 767px) {
            width: 24px;
            height: 19px;
          }
        }
      }
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid transparent;
      border-radius: 8px;
      width: 48px;
      height: 48px;

      @media (min-width: 768px) {
        width: 64px;
        height: 64px;
      }

      @media (min-width: 992px) {
        width: 32px;
        height: 32px;
      }

      @media (max-width: 991px) {
        background: @background-box;
        background-clip: padding-box;

        &::after {
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: -1px;
          right: -1px;
          background: linear-gradient(71deg, rgb(18, 16, 19), rgb(176, 175, 176), rgb(18, 16, 19));
          content: '';
          z-index: -1;
          border-radius: 8px;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: @background-box;
          background-clip: padding-box;

          &::after {
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            background: linear-gradient(71deg, rgb(18, 16, 19), rgb(176, 175, 176), rgb(18, 16, 19));
            content: '';
            z-index: -1;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .menu-list {
    margin: 40px 0 48px;
    list-style: none;

    @media (min-width: 768px) {
      margin: 40px 0 72px;
    }

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      margin: 0;
    }

    li {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      text-align: left;
      margin: 16px 0!important;
      word-wrap: break-word;

      @media (min-width: 768px) {
        margin: 24px 0!important;
        font-size: 48px;
        line-height: 72px;
      }

      @media (min-width: 992px) {
        font-size: 14px;
        line-height: 32px;
        margin: 0 12px!important;

        &:first-child {
          margin-left: 0!important;
        }
      }

      .nav-item {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        word-wrap: break-word;

        @media (min-width: 768px) {
          font-size: 48px;
          line-height: 72px;
        }

        @media (min-width: 992px) {
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
  }

  &-mobile-navigation {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3334;

    .button {
      @media (max-width: 355px) {
        display: none;
      }
    }
  }

  &-dropdown {
    &-mobile {
      display: block;

      @media (min-width: 992px) {
        display: none;
      }
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      text-align: left;
      margin-bottom: 10px;
      color: #FFFFFF;
      cursor: pointer;
      word-wrap: break-word;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &:active {
        background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-left: 16px;
      }

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 72px;
      }
    }

    &-desktop {
      display: none;

      @media (min-width: 992px) {
        display: block;
      }
    }

    &-container {
      padding-left: 24px!important;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        padding-left: 32px!important;
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;