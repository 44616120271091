@import "../../antd.customize.less";

.every-container {
  display: flex;
  justify-content: center;
}

.down-animation-block {
  height: 108vh;
  overflow: hidden;
}

.up-animation-block {
  height: auto;
  overflow: visible;
}


p {
  padding: 0;
  margin: 0;
}

.shadow-bg {
  position: fixed;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  height: 150px;
}

.hide {
  opacity: 0;
}

.transition-content {
  transition: 3s all ease;
}

.first-container-hide {
  opacity: 0;
  transition: opacity 1s ease;
}

.first-content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 85%;
  max-width: 1220px;
  margin-top: 224px;
  margin-bottom: 160px;
  align-items: center;
  position: relative;
  transition: all 1.6s ease 0.8s;

  .first-container-title {
    color: white;
    font-size: 40px;
    line-height: 56px;
    font-style: normal;
    font-weight: 700;
  }

  .first-container-description {
    font-size: 20px;
    color: @normal-font-color;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-top: 12px;
  }

  .first-container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: @normal-background;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    color: white;
    height: 54px;
    width: 280px;
    border-radius: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    margin-top: 18px;
    background-clip: padding-box;
  }

  .first-container-button div {
    z-index: -1;
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    border-image-slice: 1;
    border: double 3px transparent;
    border-radius: 12px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .first-container-button:hover span {
    color: white;
  }

  .first-container-button:hover div {
    transition: opacity 0.3s ease;
    opacity: 1;
    background-image: linear-gradient(#121726, #121726), linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
  }

  .first-container-button-description {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.first-overflow {
  overflow-x: clip;
}

.max-width {
  max-width: calc(@max-width + 80px);
}

div {
  &.sixth-container,
  &.seventh-container {
    .reset_width-40();
  }
}

.bg-purple {
  background-repeat: no-repeat;
  .image-set('/assets/bg_purple');

  background-size: 100% 100%;
  background-position: bottom center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.forth-container {
  position: relative;
  margin-top: 20px;
  width: 100%;

  .flex-container {
    display: flex;
    justify-content: center;
  }

  .forth-content-container {
    display: flex;
    justify-content: center;
    margin: 0 0 385px;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 88px;
      letter-spacing: -0.02em;
      text-align: left;
      color: white;
      max-width: 790px;
    }
  }
}

.fifth-translate {
  transform: translateY(-45%);
}

.fifth-container {
  text-align: left;
  width: 90%;
  max-width: 1400px;
  margin-bottom: 53px;

  position: relative;
  border: 2px solid transparent;
  background: linear-gradient(71deg, rgb(23, 16, 28), rgb(41, 24, 49), rgb(23, 16, 28));;
  background-clip: padding-box;
  border-radius: 45px;

  &::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(71deg, rgb(20,32,51), rgb(169,59,228), rgb(20,32,51));
    content: '';
    z-index: -1;
    border-radius: 45px;
  }


  background-size: 100% 100%;

  .fifth-content-container {
    padding: 50px 120px 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .fifth-container-title {
    font-weight: 700;
    color: white;
    font-size: 40px;
    line-height: 56px;
    font-style: normal;
    letter-spacing: -0.02em;
  }

  .fifth-investors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    max-width: 1200px;


    .logo {
      width: 40px;
      height: 40px;
    }

    .logo-without-text {
      width: 80px;
      height: 60px;
    }

    .logo-without-text-png {
      width: 80px;
      height: 30px;
    }

    .logo-without-text-think {
      width: 120px;
      height: 60px;
    }

    p {
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.02em;
    }

    .fifth-investors-loop-left {
      display: flex;
      justify-content: flex-start;
      width: 200%;

      .fifth-investors-list {
        display: flex;
        animation: investors-loop-left 30s linear infinite;

        @keyframes investors-loop-left {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-100%);
          }

        }
      }
    }

    .fifth-investors-loop-right {
      display: flex;
      justify-content: flex-start;
      width: 200%;

      .fifth-investors-list {
        display: flex;
        animation: investors-loop-right 30s linear infinite;

        @keyframes investors-loop-right {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
      }
    }

    @media screen and (min-width: 991px){

      .fifth-investors-loop-right:hover .fifth-investors-list {
        animation-play-state: paused;
      }

      .fifth-investors-loop-left:hover .fifth-investors-list {
        animation-play-state: paused;
      }
    }

  }

  .investor-container {
    border: 2px solid rgba(231, 231, 231, 0.1);
    height: 80px;
    border-radius: 12px;
    padding: 0 26px;
    max-height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    .extra-margin {
      margin-right: 7px;
    }
  }
}

.investor-between {
  margin-left: 10px;
  margin-top: 10px;
}

.sixth-container {
  text-align: left;
  width: @normal-width;
  display: grid;
  margin-top: -80px;
  background-image: url('/assets/Ellipse-for-advantages.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .sixth-content-container {
    display: flex;
    justify-content: center;
  }

  .container-title {
    text-align: center;
    padding: 0 !important;
    margin-bottom: 40px;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    line-height: 60px;
  }

  .Card-class {
    max-width: 550px;
  }

  .sixth-container-card {
    position: relative;
    border: 2px solid transparent;
    background: @background-box;
    background-clip: padding-box;
    border-radius: 45px;
    padding: 40px;
    min-height: 380px;
  }

  svg {
    width: 80px;
    height: 80px;
  }

  .bg-green-box {
    position: relative;

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(71deg, rgb(13, 18, 18), rgb(61, 160, 119), rgb(13, 18, 18));
      content: '';
      z-index: -1;
      border-radius: 45px;
    }

    .fifth-investors-loop-left {
      display: flex;
      justify-content: flex-start;
      width: 200%;

      .fifth-investors-list {
        display: flex;
        animation: investors-loop-left 35s linear infinite;

        @keyframes investors-loop-left {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-100%);
          }

        }
      }
    }

    .fifth-investors-loop-right {
      display: flex;
      justify-content: flex-start;
      width: 200%;

      .fifth-investors-list {
        display: flex;
        animation: investors-loop-right 35s linear infinite;

        @keyframes investors-loop-right {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
      }
    }

  }

  .bg-yellow-box {
    position: relative;

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(71deg, rgb(17, 14, 14), rgb(175, 162, 32), rgb(17, 14, 14));
      content: '';
      z-index: -1;
      border-radius: 45px;
    }
  }

  .bg-white-box {
    position: relative;

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(71deg, rgb(18, 16, 19), rgb(176, 175, 176), rgb(18, 16, 19));
      content: '';
      z-index: -1;
      border-radius: 45px;
    }
  }

  .bg-blue-box {
    position: relative;

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(71deg, rgb(12, 10, 14), rgb(95, 111, 173), rgb(12, 10, 14));
      content: '';
      z-index: -1;
      border-radius: 45px;
    }
  }

  .img {
    margin-bottom: 32px;
  }

  .sixth-container-title {
    font-weight: 600;
    color: white;
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 28px;
    padding-bottom: 8px;
  }

  .sixth-container-description {
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: @normal-font-color;
    font-size: 16px;
    max-width: 470px;
  }

  .investor-between {
    margin-left: 16px;
  }

  .top-investor-between {
    margin-top: 16px;
  }
}

.tooltip-link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.seventh-container {
  max-width: calc(670px * 2 + 100px);
  width: 100%;
  display: flex;
  margin-top: 60px;
  gap: 20px;

  .secondary-btn-container {
    display: flex;
  }

  button {
    position: relative;
    z-index: 1;
  }

  .seventh-container-logo {
    text-align: left;
    display: flex;
    justify-content: center;
    width: 100%;
    position: sticky;
    height: 600px;
    top: 0;
  }

  .seventh-container-title {
    font-weight: bold;
    color: white;
    font-size: 40px;
    letter-spacing: -0.02em;
    line-height: 56px;
    font-style: normal;
    font-weight: 700;
    margin-top: 60px;
  }

  .seventh-container-title-audited {
    font-weight: bold;
    color: white;
    letter-spacing: -0.02em;
    font-style: normal;
    margin-top: 60px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }

  .seventh-container-logo-audited {
    display: flex;
    margin-top: 12px;
  }

  .seventh-container-description {
    color: @normal-font-color;
    font-size: 24px;
    margin-bottom: 40px;
    line-height: 40px;
    margin-top: 8px;
    font-style: normal;
    font-weight: 600;
  }

  .seventh-content-container-logo {
    width: 100%;
    max-width: 530px;
    margin-top: 180px;

    .logo {
      display: block;
      width: 108px;
      height: 28px;
    }

    .audit-tooltip {
      color: #7f808b;
    }

    .audit-tooltip-icon {
      margin-left: 7px;
      width: 14px;
      height: 14px;
    }

    .audit-logo:not(:last-child) {
      margin-right: 20px;

      @media screen and (max-width: 425px) {
        margin-right: 10px;
      }
    }

    .logo-mobile {
      display: none;
    }
  }

  .seventh-container-icon {
    width: 100%;
    margin-top: 100px;

    .seventh-container-card {
      padding: 70px 50px;
      min-height: 556px;
      margin-bottom: 40px;
      position: relative;
      border: 2px solid transparent;
      background: @background-box;
      background-clip: padding-box;
      border-radius: 45px;

      &::after {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        background: linear-gradient(71deg, rgb(20, 32, 51), rgb(169, 59, 228), rgb(20, 32, 51));
        content: '';
        z-index: -1;
        border-radius: 45px;
      }

      .img {
        width: 200px;
      }
    }

    .seventh-container-card-title {
      font-weight: 600;
      color: white;
      font-size: 40px;
      margin-top: 50px;
      line-height: 60px;
      font-style: normal;
      letter-spacing: -0.02em;
    }

    .seventh-container-card-description {
      color: @normal-font-color;
      font-size: 24px;
      line-height: 40px;
      margin-top: 10px;
      font-style: normal;
      font-weight: 600;
      max-width: 540px;
    }
  }
}

.eighth-container {
  margin-top: 111px;
  margin-bottom: 84px;
  width: 90%;
  max-width: 1220px;
  text-align: left;

  .eighth-link-mobile {
    display: none;
  }

  .eighth-container-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;

    .eighth-link {
      width: 202px;
    }
  }

  .eighth-main-title{
    color: white;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 56px;
  }

  .eighth-card-container {
    display: flex;

    .eighth-single-card {
      text-align: left;
      width: calc(100% / 3);
      flex-basis: calc(100% / 3);
      height: 100%;
      padding: 20px 30px 50px;
      position: relative;
      border: 1px solid transparent;
      background: @background-box;
      background-clip: padding-box;
      border-radius: 40px;

      &::after {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        background: linear-gradient(71deg, rgb(18, 16, 19), rgb(176, 175, 176), rgb(18, 16, 19));
        content: '';
        z-index: -1;
        border-radius: 40px;
      }
    }

    .eighth-single-card-image {
      img {
        margin-right: -10px;
        margin-left: -10px;
        width: calc(100% + 20px);
        max-height: 237px;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  .eighth-title {
    letter-spacing: -0.02em;
    font-style: normal;
    font-weight: 600;
    color: white;
    font-size: @description-font;
    margin-top: 8px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 40px;
  }

  .eighth-description {
    font-size: 18px;
    color: @normal-font-color;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .eighth-date {
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(#05FAFF, #7A84FF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .eighth-button {
    margin-top: 29px;
    position: relative;
    z-index: 2;
  }

  .update-between {
    margin-left: 25px;
  }

  img {
    border-radius: 25px;
  }
}

.eighth-card-small {
  display: none;
}

.Card-class {
  border: 0;
  width: 100%;
  max-width: 630px;
}

.first-screen-buttons {
  display: flex;
  flex-direction: column;

  .buttons-title {
    font-size: 12px;
    color: #fff;
    opacity: 0.8;
    letter-spacing: 0.16em;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
    }

    .button-inverse {
      margin-right: 10px;
      @media screen and (max-width: 500px) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    .button {
      padding: 10px 40px;
    }
  }
}

.button {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 12px;
  line-height: 26px;

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }

  &:hover {
    -webkit-text-fill-color: unset;
  }

  &.button-inverse {
    background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
    padding: 0 16px;

    &:hover {
      background: linear-gradient(265.37deg, #05FAFF -55.16%, #7A84FF 129.88%);
    }
  }

  &.button-transparent {
    position: relative;
    border: 1px solid transparent;
    border-radius: 12px;
    background: linear-gradient(71deg, #1B171E, #1E1A21, #1B171E);
    background-clip: padding-box;
    padding: 10px;

    &:hover {
      background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
      border: 1px solid #000;
      //height: 54px;
      &::after {
        background: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    &.secondary-button {
      padding: 0;
      height: 52px;
      width: 152px;
    }

    &::after {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(71deg, rgb(20, 32, 51), rgb(169, 59, 228), rgb(20, 32, 51));
      content: '';
      z-index: -1;
      border-radius: 12px;
    }

  }
}

@media screen and (min-width: 1700px) {

  .bg-purple {
    background-size: 100% 84%;
  }

}

@media screen and (max-width: 1400px) {

  .forth-content-container {
    margin: 0 0 251px;
  }
}

@media screen and (max-width: 1200px) {

  .fifth-translate {
    transform: translateY(-34%);
  }

  .bg-purple {
    display: none;
  }

  .bg-purple-mobile {
    background-repeat: no-repeat;
    .image-set("/assets/bg_purple");
    background-size: 100% 72%;
    background-position: top center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

@media screen and (max-width: 1084px) {

  .fifth-translate {
    transform: translateY(-10%);
  }

  .first-container-button {
    font-size: @small-font-size-998 !important;
    width: 260px !important;
    height: 52px !important;
    margin-top: 6px !important;
  }

  .forth-content-container {
    margin-bottom: 140px !important;
    max-width: 650px;

    p {
      font-size: 64px !important;
      line-height: 88px !important;
    }
  }

  .fifth-container {
    width: @normal-width-998 !important;
    margin-bottom: 90px;

    .fifth-content-container {
      padding: 48px 134px 56px !important;

      .fifth-container-title {
        font-size: 28px !important;
        line-height: 40px;
        font-weight: 600;
      }
    }

    .fifth-investors {
      margin-top: 30px;
      .investor-container {
        padding: 0 16px;
      }
    }
  }

  .sixth-container {

    .investor-between {
      margin-left: 12px;
    }

    .top-investor-between {
      margin-top: 12px;
    }

    .Card-class {
      max-width: 434px;
    }

    .container-title {
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
    }

    .sixth-container-card {
      padding: 24px;
      border-radius: 24px;
      min-height: 354px;
      .sixth-container-title {
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
      }
      .sixth-container-description {
        max-width: 303px;
        line-height: 28px;
      }
    }
    .bg-green-box {
      &::after {
        border-radius: 24px;
      }
    }
    .bg-blue-box {
      &::after {
        border-radius: 24px;
      }
    }
    .bg-white-box {
      &::after {
        border-radius: 24px;
      }
    }
    .bg-yellow-box {
      &::after {
        border-radius: 24px;
      }
    }
    .img {
      margin-bottom: 24px;
      width: 70px;
      height: 70px;
    }
  }

  .seventh-container {
    margin-top: 20px;

    .seventh-content-container-logo {
      margin-top: 99px;
      max-width: 98%;


      .seventh-container-title {
        font-size: 28px;
        margin-top: 40px;
        line-height: 40px;
        font-weight: 600;
      }

      .seventh-container-description {
        line-height: 32px;
        font-size: 16px;
        max-width: 330px;
      }
    }

    .seventh-container-icon {
      .seventh-container-card {
        padding: 66px 38px;
        min-height: 354px;
        border-radius: 24px;

        &::after {
          border-radius: 24px;
        }

        .img {
          width: 70px;
          height: 70px;
        }

        .seventh-container-card-title {
          font-size: 28px !important;
          line-height: 40px !important;
          margin-top: 24px;
          font-weight: 600 !important;
        }

        .seventh-container-card-description {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }

  .eighth-container {
    margin-top: 100px;
    width: 100%;
    max-width: calc(264px * 3 + 40px);

    .eighth-container-title {
      margin-bottom: 38px;
      .eighth-link {
        width: 169px;
        height: 44px;
      }
    }

    .update-between {
      width: 20px;
      margin: 0;
    }

    .eighth-single-card {
      border-radius: 24px !important;

      &::after {
        border-radius: 24px !important;
      }
    }

    .eighth-main-title{
      font-size: @topic-font-998;
    }

    .eighth-title {
      margin-top: 12px;
      font-size: 20px;
      line-height: 28px;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }

    .eighth-description {
      font-size: 14px;
      line-height: 28px;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    .eighth-date {
      font-size: 14px;
      line-height: 28px;
    }

    div.eighth-single-card {
      padding-bottom: 32px;
    }

    .eighth-button {
      margin-top: 25px;
    }
  }

  .Card-class {
    border-radius: 30px !important;
  }
}

@media screen and (max-width: 992px) {
  .eighth-card-container {
    display: none !important;
  }

  .eighth-container {
    max-width: 100%;
    .eighth-container-title {
      padding: 0 56px;
      .eighth-main-title {
        line-height: 40px;
        font-weight: 600;
        font-size: 28px;
      }
    }
    .eighth-card-small {
      display: block !important;
      padding-left: 56px;
    }
  }

  .first-container-title {
    font-size: 40px !important;
    line-height: 56px;
    font-weight: 700;
  }

  .first-container-description {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 32px !important;
  }

  .forth-content-container {

    p {
      font-weight: 700 !important;
      font-size: 40px !important;
      line-height: 56px !important;
    }
  }
}

@media screen and (max-width: 800px) {

  .Card-class {
    border-radius: 24px !important;
  }

  .first-content-container {
    width: @normal-width-768 !important;
  }

  .first-container-title {
    font-size: 36px !important;
    line-height: 46px !important;
    font-weight: 600 !important;
  }

  .first-container-description {
    font-size: 20px !important;
    line-height: 28px !important;
    margin-top: 12px !important;
    margin-left: 6px !important;
    font-weight: 600 !important;
  }

  .first-container-button {
    border: 2px solid !important;
    font-size: @small-font-size-768 !important;
    width: 186px !important;
    height: 40px !important;
    margin-top: 6px !important;
    line-height: 24px !important;
  }

  .forth-content-container {
    margin-bottom: 180px !important;
    justify-content: flex-start;
    width: 100%;

    p {
      max-width: 590px !important;
      font-weight: 600 !important;
      font-size: 36px !important;
      line-height: 46px !important;
    }
  }

  .fifth-translate {
    transform: translateY(-15%);
  }

  .fifth-container {
    width: @normal-width-768 !important;
    border-radius: 20px;

    &::after {
      border-radius: 20px;
    }

    .fifth-content-container {
      padding: 31px 23px !important;

      .fifth-container-title {
        font-size: 30px !important;
        line-height: 46px !important;
        font-weight: 700;
      }
    }

    .investor-between {
      margin-left: 12px !important;
    }
  }

  div.sixth-container {
    .reset_width-36();
  }

  .sixth-container {
    display: grid;
    .sixth-container-card {
      height: 100%;
      .sixth-container-title {
        padding-left: 0 !important;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
      }
    }
    .container-title {
      font-size: 30px;
      line-height: 46px;
      margin-bottom: 32px;
      font-weight: 700;
    }
  }

  div.seventh-container {
    .reset_width-36();
  }

  .seventh-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px !important;

    .seventh-container-icon {
      .seventh-container-card {
        .seventh-container-card-title {
          font-size: 24px !important;
          line-height: 36px !important;
        }

        .seventh-container-card-description {
          line-height: 26px;
        }
      }
    }

    .investor-between {
      margin-left: 12px;
    }

    .Card-class {
      max-width: 346px;
    }

    .secondary-btn-container {
      justify-content: center;
    }

    .secondary-btn-container {
      justify-content: center;
    }

    .seventh-container-logo {
      position: relative;
      height: auto;

      &:after {
        background-position: center
      }
    }

    .seventh-content-container-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
      width: 100%;
      text-align: center;

      .seventh-container-title {
        font-size: 30px;
        margin-top: 33px;
        line-height: 46px;
        font-weight: 700;
      }

      .seventh-container-description {
        margin-bottom: 32px;
        max-width: 450px;
        line-height: 26px;
      }
    }

    .seventh-container-icon {
      margin-right: 0;
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .seventh-container-card {
        padding: 24px 22px 32px;
        max-width: 346px;
        margin-bottom: 12px;
      }
    }
  }

  .eighth-container {
    margin-top: 60px;

    .eighth-container-title {
      padding: 0 32px;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    .eighth-main-title {
      line-height: 46px !important;
      font-weight: 700 !important;
      font-size: 30px !important;
    }

    .eighth-card-container {
      display: none !important;
    }

    .eighth-card-small {
      display: block !important;
      padding-left: 32px;
    }

    img {
      margin: 0;
    }

    .eighth-button {
      margin-bottom: 0 !important;
    }

    .eighth-title {
      margin-top: 5px !important;
    }
  }

  .eighth-container .eighth-title {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}

@media screen and (max-width: 600px) {

  .bg-purple-mobile {
    background-size: 100% 88%;
  }

  .fifth-translate {
    transform: translateY(-10%);
  }

  .seventh-container-logo,
  .first-content-container {
    overflow: hidden;
  }

  div.first-content-container {
    .reset_width-20();
  }

  .first-content-container {
    display: flex !important;
    margin-bottom: 0 !important;
  }

  .first-container-description {
    font-size: 20px !important;
    line-height: 28px !important;
    margin-top: 12px !important;
  }

  .first-container-button {
    font-size: @small-font-size-768 !important;
    margin-top: 6px !important;
    line-height: 24px !important;
  }

  .second-card {
    display: block !important;
  }

  .mobile-class {
    border-radius: 0 0 24px 24px !important;
    height: 213px !important;
    width: 123% !important;
    margin-left: -83px !important;
  }

  .forth-container {
    margin-top: 22px !important;
  }

  .forth-content-container {
    margin-bottom: 94px !important;
    margin-left: 24px !important;
    margin-right: 24px !important;

    p {
      font-size: 36px !important;
      line-height: 46px !important;
    }
  }

  .fifth-container {
    width: @normal-width-375 !important;
    margin-bottom: 0;

    .fifth-investors {
      justify-content: flex-start;
    }

    .fifth-content-container {
      text-align: left;
      padding: 27px 25px !important;

      .fifth-container-title {
        font-size: 30px !important;
        line-height: 46px !important;
        font-weight: 700;
      }
    }
  }

  .fifth-investor-second {
    display: block;
    margin-top: 18px;
  }

  div.sixth-container {
    .reset_width-8();
  }

  .sixth-container .top-investor-between {
    margin-top: 12px;
  }

  .sixth-container {
    margin-top: 10px !important;
    background: none;

    .sixth-container-card {
      padding: 24px !important;
      border-radius: 24px !important;
      min-height: 354px;

        .sixth-container-description {
          height: 200px;
        }

        .sixth-container-title {
          padding-left: 0 !important;

        }

        .sixth-container-description {
          padding: 0 !important;
        }

      &::after {
        border-radius: 24px;
      }

      .sixth-container-title {
        font-size: @topic-font-768 !important;
        line-height: 36px !important;
      }

      .sixth-container-description {
        height: auto;
        font-size: @description-font-768 !important;
        line-height: 28px !important;
      }
    }

    .sixth-content-container {
      display: block !important;
      margin: 0 auto 0;
    }
  }

  .seventh-container {
    padding: 0 12px !important;

    .investor-between {
      margin-left: 0;
    }

    .seventh-content-container-logo {

      .seventh-container-title {
        margin-top: 14px;
      }

      .seventh-container-description {
        max-width: 343px;
      }
    }

    .seventh-container-icon {
      margin-top: 32px;
      width: 100% ;

      .seventh-container-card {
        padding: 24px 24px 32px;
      }
    }
  }

  .eighth-container {
    padding: 0 12px;
    margin-top: 80px;

    .eighth-link-mobile {
      display: flex;
      margin-top: 42px;
      max-width: 375px;
    }

    .eighth-container-title {
      padding: 0;
      .eighth-link {
        display: none;
      }
    }

    .eighth-card-container {
      display: none !important;
    }

    .eighth-card-small {
      display: block !important;
      padding-left: 0;

      .eighth-title {
        margin-top: 12px !important;
      }

      img {
        margin-left: -3px;
      }
    }
  }
}

@media screen and (max-width: 425px) {

  .down-animation-block {
    height: 101vh;
  }

  .first-content-container {
    display: flex !important;
    margin-bottom: 0 !important;
    margin-top: 135px;
  }

  .first-container-title {
    font-size: 30px !important;
    line-height: 46px !important;
    font-weight: 700 !important;
  }

  .first-container-description {
    font-size: 16px !important;
    line-height: 26px !important;
    margin-top: 8px !important;
    font-weight: 600 !important;
  }

  .first-container-button-description {
    margin-top: 30px !important;
  }

  .first-container-button {
    font-size: @small-font-size-768 !important;
    margin-top: 6px !important;
    line-height: 24px !important;
  }

  .second-card {
    display: block !important;
  }

  .mobile-class {
    border-radius: 0 0 24px 24px !important;
    height: 213px !important;
    width: 123% !important;
    margin-left: -83px !important;
  }

  .forth-container {
    margin-top: 0 !important;

    .forth-content-container {
      max-width: 327px;
    }
  }

  .fifth-container {
    width: 96% !important;

    .investor-between {
      margin-left: 12px !important;
    }

    .indents-logo {
      margin-left: 8px;
    }

    .indents-logo {
      margin-left: 8px;
    }

    .investor-between-mobile {
      margin-left: 12px !important;
    }

    .fifth-investors {
      margin-top: 24px;
    }

    .fifth-content-container {
      //padding: 24px 0 24px 20px !important;
      //text-align: left;
      align-items: center;


      .fifth-container-title {
        font-size: 20px !important;
        line-height: 28px !important;
        font-weight: 600;
      }
    }

    .investor-container {
      padding: 0 18px;
      max-height: 56px;
      .extra-margin {
        margin-right: 0;
      }
    }
  }

  .logo-without-text-think {
    width: 70px;
    height: 40px;
  }

  .logo-without-text {
    width: 60px;
    height: 30px;
  }

  .fifth-investors-loop-left{
    width: 300%;
  }

  .fifth-investors-loop-right{
    width: 300%;
  }

  div.seventh-container {
    .reset_width-8();
  }
}

@media screen and (max-height: 880px) {
  .first-content-container {
    margin-bottom: 0;
    margin-top: 160px;
  }
}

@media screen and (max-height: 700px) and (max-width: 600px) {
  .first-content-container {
    margin-bottom: 0;
    margin-top: 96px;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin-top: 48px;

  @media screen and (max-width: 768px) {
    max-width: 400px;
    width: 100%;
    margin-top: 40px;
  }

  @media screen and (max-width: 375px) {
    margin-top: 32px;
    max-width: 351px;
    width: 100%;
  }
}



@primary-color: #1DA57A;@link-color: #ffffff;