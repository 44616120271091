.team {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Sora";
  font-style: normal;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: white;
}

.teammate-card-tagname {
  background: linear-gradient(265.37deg, #05faff -55.16%, #7a84ff 129.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -text-fill-color: transparent;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.team-block-container {
  display: flex;
  flex-direction: column;
}

.title-block {
  margin: 219px 0 64px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-align: center;

  @media screen and (max-width: 992px) {
    margin: 180px 0 56px;
  }

  @media screen and (max-width: 768px) {
    margin: 148px 0 48px;
  }

  @media screen and (max-width: 375px) {
    margin: 96px 0 32px;
  }
}

.title-upper-block {
  font-weight: 600;
  font-size: 64px;
  line-height: 88px;

  @media screen and (max-width: 992px) {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
  }

  @media screen and (max-width: 375px) {
    font-size: 30px;
    line-height: 46px;
  }
}

.title-lower-block {
  font-size: 20px;
  line-height: 32px;
  opacity: 0.5;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    margin-top: 16px;
    font-size: 18px;
  }
}

.card-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 90px;
  align-self: center;
  max-width: 1256px;
  gap: 16px;

  @media screen and (max-width: 1260px) {
    max-width: 872px;
  }

  @media screen and (max-width: 992px) {
    justify-content: flex-start;
    margin: 0 auto 80px;
    max-width: calc(100% - 120px);
  }

  @media screen and (max-width: 768px) {
    max-width: calc(100% - 92px);
    margin: 0 48px 80px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    max-width: calc(100% - 24px);
    margin: 0 12px 64px;
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;