@import "../../antd.customize.less";

.text-about-hubble {
  position: relative;
  color: @normal-color;
  line-height: 88px;
  text-align: left;
  width: 100%;
  padding-bottom: 105px;
  z-index: -1;

  p {
      font-size: 64px;
      font-weight: 600;
      letter-spacing: -0.02em;
      width: 64%;
      max-width: 930px;
    }

    span {
      color: rgba(255, 255, 255, 0.5);
    }
}

@media screen and (max-width: 1100px) {

  .text-about-hubble {
    display: flex;
    justify-content: center;
    p {
      width: 90%;
      max-width: 100%;
      line-height: 88px;
    }
  }
}

@media screen and (max-width: 992px) {

  .text-about-hubble {
    p {
      width: 798px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {

  .text-about-hubble {
    p {
      width: 87%;
    }
  }

}

@media screen and (max-width: 500px) {
  .text-about-hubble {
    padding-bottom: 60px;
    p {
      width: 93%;
      font-size: 36px;
      line-height: 46px;
    }
  }
}
@primary-color: #1DA57A;@link-color: #ffffff;