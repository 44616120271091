@import "../../antd.customize.less";

.footer {
  background: @background-box;
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 34px 0 0;

  @media (min-width: 768px) {
    padding: 34px 0 50px;
  }

  @media (min-width: 992px) {
    padding: 34px 0 60px;
  }

  &-center {
    max-width: @max-width;
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  &-space-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: inherit;

    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &-logo {
    width: 100%;
    display: block;
    margin-bottom: 48px;

    @media (max-width: 375px) {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      margin-bottom: 35px;
    }

    @media (max-width: 991px) {
      margin-bottom: 40px;
      display: none;
    }

    img {
      width: 108px;
      height: 28px;
    }

    &-mobile {
      display: none;
      width: 100%;
      margin-bottom: 30px;

      @media (max-width: 767px) {
        display: block;
      }
    }

    &-table {
      display: none;
      width: 100%;
      margin-bottom: 35px;

      @media (min-width: 768px) and (max-width: 991px) {
        display: block;
      }
    }
  }

  &-menu {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 861px) {
      width: 50%;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-right: 40px;
      width: 100%;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        margin-bottom: 20px;
      }

      @media (min-width: 768px) {
        width: auto;
      }

      li {
        margin-bottom: 12px;
      }
    }

    &-link {
      font-size: @small-font-size;
      color: @normal-font-color;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      opacity: 0.5;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    &-title {
      font-size: @normal-font-description;
      color: white;
      font-style: normal;
      font-weight: bold;
      line-height: 32px;
    }
  }

  &-subscribe {
    max-width: 560px;
    width: 50%;

    @media (max-width: 860px) {
      width: auto;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 60px;
    }

    @media (max-width: 460px) {
      padding-right: 0;
    }

    &-mobile {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    input {
      width: 100% !important;
    }
  }

  &-input {
    width: 100%;

    @media (max-width: 860px) {
      max-width: 410px;
      width: auto;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &-social {
    display: flex;
    margin-top: 62px;

    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 40px;
    }

    @media (max-width: 600px) {
      overflow: scroll;
    }

    a {
      font-size: @small-font-size;
      margin-right: 20px;
      color: white !important;
      font-style: normal;
      font-weight: bold;
      line-height: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;