@import "../../antd.customize.less";

.sample-button {
  font-size: 16px;
  color: white;
  border-radius: 12px;
  border: 0px;
  height: 44px;
  width: 124px;
  font-style: normal;
  font-weight: bold;
  line-height: 32px;
  background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
}

&:hover {
  -webkit-text-fill-color: unset;
}

.sample-button:hover {  
  color: white;
  background: linear-gradient(265.37deg, #05FAFF -55.16%, #7A84FF 129.88%);
  
}

.sample-button:focus {
  color: white;
  background: linear-gradient(265.37deg, #05FAFF -55.16%, #7A84FF 129.88%);
}

@media screen and (max-width: 800px) {
  .sample-button {
    font-size: 16px !important;
    height: 44px !important;
    width: 140px;
    border-radius: 8px;
    background: linear-gradient(147.64deg, #FF00FF -36.28%, #7A84FF 67.42%, #05FAFF 187.88%);
  }
}

@media screen and (max-width: 600px) {
  .sample-button {
    line-height: 32px;
  }
}

@primary-color: #1DA57A;@link-color: #ffffff;